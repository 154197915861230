<template >
  <div  >
    <div id="back">
      <el-image @click="goJidi()" style="width: 22px; height: 22px; box-sizing: content-box;vertical-align:middle;margin-left: 24px" :src="require('../../assets/basegreenhouse/btn_return.png')" />
      <el-button style="color: #8D8E98;" @click="goJidi()">基地监测数据</el-button>
      <el-image @click="goBack()" style="width: 7px; height: 11px; box-sizing: content-box;vertical-align:middle;margin-left: 10px" :src="require('../../assets/basegreenhouse/triangle_right.png')" />
      <el-button style="margin-left: 10px;color: #8D8E98;" @click="goBack()">{{name}}</el-button>
      <el-image @click="goBack()" style="width: 7px; height: 11px; box-sizing: content-box;vertical-align:middle;margin-left: 10px" :src="require('../../assets/basegreenhouse/triangle_right.png')" />
      <el-button style="margin-left: 10px;">{{title}}</el-button>
    </div>

    <div style="height: 160px;width: auto;margin-top: 24px;  box-sizing: border-box;">
      <div style="height: 160px;background: white; border-radius:6px;margin-left: 24px;margin-right: 20px">
        <div style="padding-top: 15px">
          <el-image  style=" margin-left: 21px;box-sizing: content-box;vertical-align:middle;" :src="require('../../assets/basegreenhouse/base.png')" />
          <el-select style="border: none;margin-left: 18px;" v-model="id"  @change="handleChange" filterable collapse-tags   placeholder="">
            <el-option
                    v-for="item in jidiName"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>

          </el-select>
          <hr style="margin: 16px 0 0 0;background: #E5E5E5;height:0.8px; border:none;"/>
          <div style="margin: 22px 0 0 21px;" >
            <div style="width: 217px;float: left;">
              <el-image class="hezuo_img" :src="require('../../assets/basegreenhouse/ic_area.png')" />
              <p><span class="hezuo_mian">{{crop}}</span></p>
              <p class="hezuo_biao">种植作物</p>
            </div>
            <div style="width: 217px;float: left;">
              <el-image class="hezuo_img" :src="require('../../assets/basegreenhouse/ic_number.png')" />
              <p><span class="hezuo_mian">{{area}}</span><span style="font-size: 14px">㎡</span></p>
              <p class="hezuo_biao" >棚内种植面积</p>
            </div>
            <div style="width:350px;float: left;">
              <el-image class="hezuo_img" :src="require('../../assets/basegreenhouse/ic_site.png')" />
              <p class="hezuo_mian" style="font-size: 16px">{{admin}}</p>
              <p class="hezuo_biao" >大棚管理员</p>
            </div>

          </div>
        </div>
      </div>

    </div>
    <div style="width:100%;height:calc(100vh - 330px);overflow: hidden;position: relative; margin-top: 10px;">
      <div class="head-title-box_abnormal">

        <!--空气温度-->
        <airTemp style="background: white;border: none;border-radius: 6px;margin-right: 27px;" :temperList="temperList"></airTemp>
        <div style="display:flex;margin-right: 27px;">
          <!--土壤温度-->
          <airHumidity style="background: white;border: none;border-radius: 6px;" :landTemperList="landTemperList"></airHumidity>
          <!--光照强度-->
          <soilHumidity :lightList="lightList" style="margin-left: 10px;background: white;border: none;border-radius: 6px;"></soilHumidity>
        </div>
        <div style="display:flex;margin-right: 27px;">
          <!--空气湿度-->
          <lightHumidity style="background: white;border: none;border-radius: 6px;" :humidList="humidList"></lightHumidity>
          <!--二氧化碳浓度-->
          <soilTemp :co2List="co2List" style="margin-left: 10px;background: white;border: none;border-radius: 6px;"></soilTemp>
        </div>
        <div style="display:flex;margin-right: 27px;">
          <!--水肥PH值-->
          <waterPH style="background: white;border: none;border-radius: 6px;" :landPHList="landPHList"></waterPH>
          <!--土壤湿度-->
          <co2Humidity :landHumidList="landHumidList" style="margin-left: 10px;background: white;border: none;border-radius: 6px;"></co2Humidity>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import airTemp from "@/components/echarts/airTemp";
import airHumidity from "@/components/echarts/airHumidity";
import soilHumidity from "@/components/echarts/soilHumidity";
import soilTemp from "@/components/echarts/soilTemp";
import lightHumidity from "@/components/echarts/lightHumidity";
import waterPH from "@/components/echarts/waterPH";
import co2Humidity from "@/components/echarts/co2Humidity";
import moment from "moment";

export default {
  name: "qiheGrennhouseDetails",
  data() {
    return {
      name: "",
      title: "",
      bid: "",
      id: 0,
      prePath:"",
      tableData: [],
      dialogVisible: false,
      value1: '',
      waringList: [],
      value:'',
      selectValue:'',//选中的值
      jidiName:[],
      allAreas:'',//基地面积
      address:'',//基地位置
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      area:'',//面积
      crop:'',//作物
      admin:'',//管理员
      changeList: [],
      temperList: [],//空气温度
      landTemperList: [],//土壤温度
      lightList: [],//光照强度
      humidList: [],//空气湿度
      co2List: [],//二氧化碳浓度
      landPHList: [],//水肥PH值
      landHumidList: [],//土壤湿度
      date:new Date(),//当天时间
    }
  },

  components: {
    airTemp,
    airHumidity,
    soilHumidity,
    lightHumidity,
    soilTemp,
    waterPH,
    co2Humidity
  },
  created() {
    this.title = this.$route.query.title
    this.name = this.$route.query.name
    this.bid = this.$route.query.bid
    this.id = parseInt(this.$route.query.id)//大棚id
    this.area= this.$route.query.area
    this.crop= this.$route.query.crop
    this.admin=this.$route.query.admin
    // this.getData()
  },
  mounted() {
    // this.selectValue = this.id;
    this.date=moment(this.date).format('YYYY-MM-DD');
    // this.date='2022-02-11'
    this.getData()

    this.getDataNameList()
  },

  methods: {
     getData() {

      this.$api.post('data/getTodayList', {bid: this.bid,gid: this.id,start:this.date}, result => {
      // this.$api.post('data/getTodayList', {bid: this.id}, result => {
        this.temperList = result.temperList
        this.landTemperList = result.landTemperList
        this.lightList = result.lightList
        this.humidList = result.humidList
        this.co2List = result.co2List
        this.landPHList = result.landPHList
        this.landHumidList = result.landHumidList
      })
    },
    handleChange(value){
       console.log("value="+value)
      this.id=value
      this.area= this.jidiName.find(item =>item.id === value).area
      this.crop= this.jidiName.find(item =>item.id === value).crop
      this.admin= this.jidiName.find(item =>item.id === value).admin
      console.log("idArr="+this.area)
      this.getData()
    },

    //返回基地检测
    goJidi() {
      // this.$router.go(-1,);
      this.$router.push({
        name: "qiheIndex",
        query: {

        }
      })
    },
    //返回上一级
    goBack() {
      // this.$router.go(-1,);
      this.$router.push({
        name: "grennhouse",
        query: {
          bid: this.bid,
          names: this.name
        }
      })
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    getDataNameList(){
      this.$api.get('gHouse/getGreenhouseList?bid='+this.bid, {}, result => {
        this.jidiName = result.list

      })
    },
  },
  watch:{
    'id':{
      handler(newValue,oldValue){
        this.pageIndex=1
        this.id=newValue
        this.getData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .body{
  }
  .page {
    width: auto;
    margin: 0 auto;
    margin-left: 240px;
    height: auto;
  }
#back {
  height: 54px;
  background: #F9FCFD;


}

#back .el-button {
  height: 53px;
  color: #333;
  border: none;
  background: none;
  padding: 0;
  margin: 0 0 0 10px;
  //margin-top: 10px;
}

.head-title-box {
  height: 300px;
  background: #FFFFFF;
  margin-top: 20px;
  text-align: center;
  //margin-bottom: 30px;
}

.head-title-box_abnormal {
  width: calc(100%);
  height: calc(100vh - 320px);
  margin: -10px 20px 0 24px;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;

}

.head-title-con-name {
  margin-top: 9px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;

}

.head-title-con-type {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}


.head-title-con-left {
  height: 76px;
  margin-top: 10px;
}

.head-title-con {
  height: 76px;
  line-height: 76px;
  cursor: pointer;
}

.head-title-con-box {
  height: 200px;
  cursor: pointer;
  padding: 0 5px;
}

.head-title-con-box_actice {
  height: 200px;
  background: #CBE9CB;
  cursor: pointer;
  padding: 0 5px;
}

/*.head-title-con-img {*/
/*  height: 80px;*/
/*}*/


.head-title-con-type2 {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-top: 25px;

}

.head-title-con-data {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #0090FF;
  margin-top: 6px;
}

  /deep/.sousuo .el-select{
    background: none !important;
    border: none !important;
  }

  /*.el-input.el-input--suffix {*/
  /*// 修改背景颜色、字体颜色、边框、宽高*/
  ::v-deep.sousuo .el-select .el-input__inner {
    background: none !important;
    border: none  !important;
    color: #333333  !important;

  }
  /deep/.el-input__inner {
    background: none !important;
    border: none  !important;
    color: #333333  !important;

  }
  /*右侧图标样式  \e790下箭头图标*/
  /deep/.el-select .el-input .el-select__caret.el-input__icon::before {
    content: "\e790";
    color: #134453;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  /*如果不设置图标下拉时是反的*/
  /deep/.el-select .el-input .el-select__caret {
    transform: rotateZ(0deg);
  }
  p{
    padding: 0;
    margin: 0;
  }
  .hezuo_img{
    float:left;
    width: 44px;
    height: 44px;
    margin-right: 14px;
  }
  .hezuo_mian{
    min-height: 25px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  .hezuo_biao{
    font-size: 14px;
    font-weight: 400;
    color: #8D8E98;
  }
</style>
